.post-type-archive-data {
  background-image: url('../images/data-dashboard.jpg');
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: $raven-60;
    position: absolute;
    top: 0;
    z-index: -1;
  }

  .wrap {
    z-index: 1;
  }

  .banner:after {
    background: none;
  }

  .dashboard-header {
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 20px;

    @media (min-width: $screen-md-min) {
      padding-top: 100px;
    }
  }

  .entry-title {
    color: $white;
    font-size: 54px;
    font-family: $font-family-sans-serif;
    margin: .25em 0;
  }

  .subtitle {
    // font-family: $font-family-serif;
    font-weight: bold;
    font-style: italic;
    color: $pear;
    font-size: 22px;
  }

  .popover-title {
    background: $steel;
    color: $ash;
    border: none;
  }
}

.dashboard-section {
  margin-bottom: 2em;

  h2 {
    margin: 0 -10px 10px;
    padding: 30px 10px 1em;
    color: $white;
    background: $stone-70;

    span {
      font-weight: 300;
      color: $steel;
    }
  }

  .intro-section {
    padding-top: 1em;
    position: relative;
    overflow: hidden;
    color: $raven;
    background: $white;

    & > div {
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      background: url('../images/data-dashboard-light.jpg') no-repeat;
      background-size: 100%;
      opacity: .5;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  }
}

.data-section {
  background: $pearl;
  margin-bottom: 10px;
  padding: 0;

  .wp-embed & {
    padding: 10px;
    margin-bottom: 0;

    .embed-credit {
      width: 64px;
    }

    @media (max-width: $screen-sm-max) {
      p.no-bottom-margin {
        padding-right: 70px;
      }

      .embed-credit {
        top: 0;
        width: 54px;
      }
    }
  }

  & > div {
    padding: 10px;
  }

  .panel {
    margin-bottom: 0;
  }

  .fancy-number {
    color: $plum-lighter;
    margin-bottom: 10px;
  }
}

.data-dashboard {
  .well, .panel {
    border-radius: 0;
    box-shadow: none;
    border: none;
  }

  .callout {
    background: $steel;
    overflow: visible;

    @media (min-width: $screen-md-min) {
      max-width: 295px;
    }

    &.affix-bottom {
      width: 100% !important;
    }

    a {
      color: $pearl;
      // text-decoration: none;
    }
  }

  .nav {
    li {
      li {
        color: $pearl;

        a {
          color: $pearl;
        }

        &.active {
          & > a {
            color: $pearl;
          }
        }
      }

      &.active {
        & > a {
          color: $white;
          background: $gray;

          span {
            color: $white;
          }
        }
      }
    }

    a {
      color: $pearl;

      span {
        color: $ash;
      }

      &:hover, &:focus {
        background: $pearl;
        color: $steel;
      }
    }
  }

  .modal-toggle {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    background: $denim;
    color: $white;
    text-decoration: none;
    text-align: center;
    font-size: 30px;
    line-height: 54px;
    width: 64px;
    padding: 10px 10px 0;
    z-index: 100;

    span {
      display: block;
    }

    &:hover {
      background: $denim-darker;
      color: $white;
      text-decoration: none;
    }
  }
}

// Google tables
.google-visualization-table {
  .google-visualization-table-table {
    font-family: $font-family-sans-serif;

    .frozen-column {
      background: $white;
    }

    .gtable-row-odd {
      td {
        background: lighten($steel, 7%);
      }
    }

    .gtable-head-cell {
      background: $denim-lightest;
    }

    .gtable-cell {
      border: none;
    }

    .last-frozen-column {
      border-right: 1px solid $pearl;
    }
  }
}
