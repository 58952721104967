.loading {
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $stone-70;
    z-index: 10;
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -35px 0 0 -35px;
    font-size: 10px;
    border-top: 1em solid rgba(255, 255, 255, 0.2);
    border-right: 1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1em solid rgba(255, 255, 255, 0.2);
    border-left: 1em solid #ffffff;
    animation: loading 1.1s infinite linear;
    z-index: 20;

    &, &:after {
      border-radius: 50%;
      width: 70px;
      height: 70px;
    }
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
