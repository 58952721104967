.social-share-modal {
  a.icon-share {
    position: fixed;
    width: 48px;
    height: 48px;
    right: 0;
    bottom: 57px;
    z-index: 100;
    background: $pear;
    text-decoration: none;
    color: $white;

    &:before {
      position: absolute;
      font-size: 32px;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }

    @media (min-width: $screen-sm-min) {
      bottom: 0;
    }

    @media (min-width: $screen-md-min) {
      display: none;
    }
  }

  .modal-dialog {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;

    .social-share-link {
      display: inline-block;
    }

    .social-share-embed {
      text-align: left;
      background: $stone;
      padding: 1px 10px 10px;

      h6 {
        color: $white;
      }
    }

    .wp-embed-share-input {
      width: 100%;
      border: none;
      height: 28px;
      margin: 0 0 10px;
      padding: 0 5px;
      font: 400 14px/1.5 $font-family-sans-serif;
      resize: none;
      cursor: text;
    }

    textarea.wp-embed-share-input {
      height: 74px;
    }

    .wp-embed-share-description {
      margin: 0;
      font-size: 14px;
      line-height: 1;
      font-style: italic;
      color: $pearl;
    }
  }

  @media screen and (orientation:portrait) {
    body:not(.single-data-viz):not(.post-type-archive-data) & {
      .modal-dialog {
        width: 100px;
        margin-top: -240px;
        margin-left: -50px;
      }
    }
  }

  @media screen and (orientation:landscape) {
    .modal-dialog {
      width: 480px;
      margin-top: -50px;
      margin-left: -240px;
    }
  }

  .single-data-viz &, .post-type-archive-data & {
    .modal-dialog {
      max-width: 480px;
      margin-top: -175px;
      margin-left: -240px;

      @media (max-width: 480px) {
        width: 100%;
        margin-left: -50%;
      }
    }
  }
}

.social-share-buttons {
  display: none;

  @media (min-width: $screen-md-min) {
    display: block;
    position: fixed;
    left: 0;
    top: 30%;
    z-index: 100;
    background: $white70;

    .count {
      font-family: $font-family-sans-serif;
      font-weight: 300;
      font-size: 19px;
      text-align: center;
      width: 100%;
      line-height: 1.2;

      .num {
        display: block;
        font-size: 28px;
        font-family: 'Open Sans Condensed';
      }
    }
  }
}

.social-share-link {
  display: block;
  text-indent: -100em;
  overflow: hidden;
  width: 100px;
  height: 100px;
  position: relative;
  text-decoration: none;
  margin: 16px auto;

  &:before {
    color: $white;
    text-indent: 0;
    position: absolute;
    font-size: 48px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }

  @media screen and (max-width: $screen-sm-max) and (orientation: landscape) {
    display: inline-block;
    margin: 0 8px;
  }

  @media (min-width: $screen-md-min) {
    margin: 0;
    width: 60px;
    height: 60px;

    &:before {
      font-size: 32px;
    }
  }

  .single-data-viz &, .post-type-archive-data & {
    margin: 0;
    width: 60px;
    height: 60px;

    &:before {
      font-size: 32px;
    }
  }

  &.icon-facebook {
    background: #3b5998;

    &:hover {
      background: darken(#3b5998, 10%);
    }
  }

  &.icon-twitter {
    background: #00aced;

    &:hover {
      background: darken(#00aced, 10%);
    }
  }

  &.icon-linkedin {
    background: #007bb6;

    &:hover {
      background: darken(#007bb6, 10%);
    }
  }

  &.icon-email {
    background: $scarlet;

    &:hover {
      background: darken($scarlet, 10%);
    }
  }
}
.other-share {
  @extend .social-share-link;

  &.icon-download {
    background: $indigo;

    &:hover {
      background: darken($indigo, 10%);
    }
  }

  &.icon-fax {
    background: $pear;

    &:hover {
      background: darken($pear, 10%);
    }
  }

  .single-data-viz &, .post-type-archive-data & {
    @media screen and (max-width: $screen-sm-max) {
      display: inline-block !important;
    }
  }
}
