.btn {
  border: none;
  border-radius: 0;
  font-family: $font-family-sans-serif;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  white-space: normal;
}

.btn-gray {
  color: $white;
  background: $ash;
  border: none;
  padding: 6px 30px;

  &:hover, &:active, &:focus {
    background: $raven;
  }
}

.button {
  @extend .btn;
}
