.page-header {
  &.photo-overlay {
    position: relative;
    margin-bottom: 2em;
    background-position: center;
    background-size: cover;
    height: 139px;

    .article-title-overlay {
      position: absolute;
      padding-bottom: .5em;
      color: $white;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 2;

      h1 {
        border-bottom: none;
      }
    }

    @media (min-width: $screen-md-min) {
      height: 262px;
    }

    @media (max-width: $screen-xs-max) {
      .entry-title {
        font-size: 2rem;
      }
    }
  }
}

.block-person {
  margin-bottom: 2em;

  .staff & > div {
    background: $steel;
    text-align: center;
  }

  .attachment-bio-headshot {
    transition: transform .3s;
  }

  .post-title {
    margin: 0;
    font-size: 20px;

    .staff & {
      color: $white;
      background: $plum-darkest;
      padding: .5em;
    }

    a {
      color: $ash;
      text-decoration: none;
    }
  }

  & > div:hover {
    .attachment-bio-headshot {
      transform: scale(1.05);
    }
  }

  @media (min-width: $screen-sm-min) {
    text-align: left;
  }
}

.staff, .ncga {
  @media (max-width: $screen-xs-max) {
    .block-person:nth-child(2n + 1) {
      clear: left;
    }
  }

  @media (min-width: $screen-sm-min) {
    .block-person:nth-child(4n + 1) {
      clear: left;
    }
  }
}

.ncga {
  .block-person {
    img {
      width: 100%;
    }
  }
}

.contributors {
  .post-title {
    border-bottom: 1px solid transparent;
    transition: all .3s;
  }

  .block-person > div:hover {
    .post-title {
      border-bottom: 1px solid $heather;
    }
  }

  @media (max-width: $screen-xs-max) {
    .block-person {
      text-align: center;
    }

    .block-person:nth-child(2n + 1) {
      clear: left;
    }
  }

  @media (min-width: $screen-sm-min) {
    .block-person:nth-child(3n + 1) {
      clear: left;
    }
  }
}

.board-of-directors {
  table {
    max-width: 100%;

    td:first-child {
      max-width: 50%;
    }

    img {
      margin-right: 5px;
      max-width: 100px;

      @media (min-width: $screen-sm-min) {
        max-width: 200px;
      }
    }

    h3 {
      margin-top: 0;
    }
  }
}

.district-map {
  width: 100%;
}
