@charset "UTF-8";

@font-face {
  font-family: "ednc";
  src:url("../fonts/ednc.eot?v=1");
  src:url("../fonts/ednc.eot?#iefix&v=1") format("embedded-opentype"),
    url("../fonts/ednc.woff?v=1") format("woff"),
    url("../fonts/ednc.ttf?v=1") format("truetype"),
    url("../fonts/ednc.svg#ednc?v=1") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "ednc" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "ednc" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
  content: "\61";
}
.icon-facebook:before {
  content: "\62";
}
.icon-twitter:before {
  content: "\63";
}
.icon-retweet:before {
  content: "\64";
}
.icon-reply:before {
  content: "\65";
}
.icon-star:before {
  content: "\66";
}
.icon-youtube:before {
  content: "\67";
}
.icon-gplus:before {
  content: "\68";
}
.icon-linkedin:before {
  content: "\69";
}
.icon-instagram:before {
  content: "\6a";
}
.icon-external-link:before {
  content: "\6b";
}
.icon-email:before {
  content: "\6c";
}
.icon-phone:before {
  content: "\6d";
}
.icon-fax:before {
  content: "\6e";
}
.icon-website:before {
  content: "\6f";
}
.icon-rss:before {
  content: "\70";
}
.icon-video:before {
  content: "\71";
}
.icon-vimeo:before {
  content: "\72";
}
.icon-calendar:before {
  content: "\73";
}
.icon-academic:before {
  content: "\74";
}
.icon-vine:before {
  content: "\75";
}
.icon-home:before {
  content: "\76";
}
.icon-share:before {
  content: "\77";
}
.icon-share-dots:before {
  content: "\78";
}
.icon-download:before {
  content: "\79";
}
