.mobile-bar {
  background: $steel;
  height: 55px;
  position: relative;
  z-index: 200;

  @media (min-width: $screen-sm-min) {
    height: 80px;
  }
}

.mobile-logo {
  width: 55px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 0;

  @media (min-width: $screen-sm-min) {
    width: 80px;
    height: 80px;
    top: 15px;
    left: 15px;
  }
}

.mobile-menu {
  position: absolute;
  top: 0;
  right: 0;
  height: 55px;

  @media (min-width: $screen-sm-min) {
    height: 80px;
  }

  .dropdown-menu {
    right: 0;
    left: auto;
  }
}

.nav-toggle {
  position: absolute;
  top: 19px;
  right: 10px;
  cursor: pointer;
  padding: 8px 30px 16px 0;
  color: transparent;
  overflow: hidden;

  @media (min-width: $screen-sm-min) {
    top: 30px;
    right: 15px;
    padding-left: 55px;

    &:before {
      content: 'MENU';
      color: $jet;
      font-family: $font-family-sans-serif;
      position: absolute;
      left: 0px;
      top: -2px;
      font-size: 16px;
      padding-right: 7px;
    }
  }

  span, span:before, span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 2px;
    width: 30px;
    background: $jet;
    position: absolute;
    display: block;
    content: '';
    transition: all 200ms ease-in-out;
    color: transparent;
  }

  span:before {
    top: -8px;
  }

  span:after {
    bottom: -8px;
  }

  &.active {
    span {
      background-color: transparent;
    }

    span:before, span:after {
      top: 0;
    }

    span:before {
      transform: rotate(45deg);
    }

    span:after {
      transform: rotate(-45deg);
    }
  }
}

.mobile-nav {
  position: absolute;
  right: 0;
  top: 100%;
  width: 320px;
  background: $steel;
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;

  .active + & {
    display: block;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .menu {
    a, .widgettitle-in-submenu {
      text-decoration: none;
      display: block;
      font-family: $font-family-sans-serif;
      text-transform: uppercase;
      color: $jet;
      padding: 10px 20px;
      margin: 0;
      font-size: 18px;
      line-height: 1.4em;
    }
  }

  .widgettitle-in-submenu {
    & + div {
      background: $heather;
      overflow: hidden;
      height: 0;
    }

    &.open + div {
      height: auto;
    }
  }

  .social-media {
    padding: 10px 20px;

    a {
      color: $ash;
      text-decoration: none;
      font-size: 20px;
    }
  }

  .btn {
    text-transform: none;
    font-size: .9em;
    line-height: 2em;
    padding: 6px 22px;
  }
}

.expandable-title, .widgettitle-in-submenu {
  position: relative;

  .caret {
    display: block;
    position: absolute;
    right: 20px;;
    top: 20px;
    border-width: 7px;
    transform: rotate(-90deg);
    transition: transform 200ms ease-in-out;
  }

  &.open .caret {
    transform: rotate(0deg);
  }
}

.expandable {
  background: $pearl;
  overflow: hidden;
  height: 0;
  transition: height 200ms ease-in-out;

  .open + & {
    height: auto;
  }
}

.widget_nav_menu .menu {
  a {
    text-transform: none;
  }
}

.mobile-search {
  padding: 10px 20px;
  position: relative;

  .form-control {
    width: 100%;
    height: 44px;
    padding: 6px 12px;
    font-size: 18px;
  }

  .btn {
    display: none;
  }

  .icon-search {
    position: absolute;
    top: 21px;
    right: 30px;
    color: $ash;
    text-decoration: none;
    font-size: 20px;
  }
}
