img {
  max-width: 100%;
  height: auto;
}

.parallax-img {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
}

// Gallery shortcode
.gallery-row {
  padding: ($line-height-computed / 2) 0;

  img {
    width: 100%;
  }
}

.collage-wrapper {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  margin-bottom: 34px;

  body:not(.single-flash-cards) & {
    @media (min-width: $screen-md-min) {
      margin-left: -7.35%;
      margin-right: -7.35%;
      max-width: 114.7%;
    }
  }

  .mce-content-body &, .popmake-content & {
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: 100% !important;
  }
}

.gallery {
  padding: ($line-height-computed / 2) 0;
  margin-bottom: 1.88em;

  // .gallery-caption {
  //   bottom: 5px;
  //   left: 5px;
  //   right: 5px;
  // }

  &:not(.collage):not(.g-carousel) {
    .gallery-item {
      float: left;
      padding: 5px;
      position: relative;
    }
  }

  &.collage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .gallery-item {
      width: 50%;
      position: relative;
      overflow: hidden;

      &.portrait {
        flex-basis: 100%;
      }

      &.landscape {
        flex-basis: 50%;
      }

      .gallery-icon {
        border: 5px solid $white;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
      }

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
    }
  }

  @media (min-width: $screen-md-min) {
    &:not(.fullwidth):not(.collage):not(.g-carousel) {
      body:not(.single-flash-cards):not(.single-data-viz):not(.post-type-archive-data) & {
        margin-left: -7.35%;
        margin-right: -7.35%;
        max-width: 114.7%;
      }
    }
  }

  @media (min-width: $screen-sm-min) {
    &:not(.collage):not(.g-carousel) {
      &.gallery-columns-5 {
        .gallery-item {
          width: 20%;
        }
      }

      &.gallery-columns-4 {
        .gallery-item {
          width: 25%;
        }
      }

      &.gallery-columns-3 {
        .gallery-item {
          width: 33.33333%;
        }
      }

      &.gallery-columns-2 {
        .gallery-item {
          width: 50%;
        }
      }
    }
  }

  .thumbnail {
    margin-bottom: 0;
  }

  img {
    width: 100%;
  }
}

// Gallery slideshow
.g-carousel {
  // Only show first item on page load
  .gallery-item {
    display: none;

    &:first-child {
      display: block;
    }
  }

  .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;

    .owl-dot {
      display: inline-block;
      zoom: 1;
      *display: inline;

      span {
        width: 10px;
        height: 10px;
        margin: 5px 7px;
        background: $steel;
        display: block;
        -webkit-backface-visibility: visible;
        transition: opacity 200ms ease;
        border-radius: 30px;
      }

      &.active, &:hover {
        span {
          background: $gray;
        }
      }
    }
  }
}

// Embeds
.object-wrapper {
  position: relative;
  padding-bottom: 75%;
  padding-top: 25px;
  height: 0;

  object, embed {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

object, embed {
  max-width: 100%;
}

.entry-content-asset {
  &.twitter, &.instagram {
    max-width: 500px;
    margin: 0 auto 34px;
  }

  &:not(.instagram):not(.twitter):not(.wp-embed) {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    margin-bottom: 34px;

    body:not(.page):not(.single-flash-cards):not(.single-edtalk):not(.post-type-archive-data):not(.single-data-viz) & {
      @media (min-width: $screen-md-min) {
        margin-left: -7.35%;
        margin-right: -7.35%;
        max-width: 114.7%;
      }

      &.alignleft.alignleft {
        @media (min-width: 545px) {
          width: 57.35%;
          margin-right: ($line-height-computed / 2);
          padding-bottom: 30%;
        }
      }
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .mce-content-body &, .popmake-content & {
      margin-left: 0 !important;
      margin-right: 0 !important;
      max-width: 100% !important;
    }
  }

  &.flash-cards.flash-cards {
    padding-top: 50px !important;
    padding-bottom: 75% !important;
  }

  &.soundcloud.soundcloud {
    padding-top: 0;
    padding-bottom: 166px !important;
  }

  &.slideshare.slideshare {
    padding-bottom: 100% !important;
  }

  &.wp-embed {
    body:not(.page):not(.single-flash-cards):not(.single-edtalk):not(.post-type-archive-data):not(.single-data-viz) & {
      @media (min-width: $screen-md-min) {
        margin-left: -7.35%;
        margin-right: -7.35%;
        max-width: 114.7%;
      }
    }

    iframe {
      width: 100%;
    }
  }
}

// Video
.wp-video {
  margin-bottom: 34px;
}

// Audio player
.media-embed-container.mejs-container {
  margin-bottom: 34px;

  .mejs-controls {
    background: $stone;

    .mejs-time-current {
      background: $pear-lighter;
    }
  }
}

// Audio playlist
.entry-content {
  .wp-playlist {
    background: $steel;
    border: none;
    margin-bottom: 34px;

    .wp-playlist-playing {
      background: $pearl;
    }

  }

  .wp-playlist-tracks {
    .wp-playlist-caption {
      font-family: $font-family-sans-serif;
      font-size: 16px;
      padding: 8px 0;
    }

    .wp-playlist-item-length {
      padding: 8px 0;
    }

    .wp-playlist-item-title {
      font-size: inherit;
      line-height: inherit;
    }
  }

  .wp-playlist-item {
    border-bottom-color: $pearl;
  }

  .wp-playlist-current-item {
    min-height: 60px;
    height: auto;

    .wp-playlist-item-title {
      font-family: $font-family-serif;
      font-size: 20px;
    }
  }
}
