.map-container {
  margin-bottom: 1.88em;
}

.map-mobile {
  margin: 0 auto;
  width: 320px;
}

.map-mobile-scroll {
  width: 100%;
  min-height: .01%;
  overflow-y: hidden;
  overflow-x: auto;
}

.map-tablet {
  margin: 0 auto;
  width: 764px;
}

.map-desktop {
  margin: 0 auto;
  width: 1024px;
}

.map-desktop,
.map-tablet {
  display: none;
}

@media (min-width: 1024px) {
  .map-desktop {
    display: block;
  }

  .map-tablet,
  .map-mobile,
  .map-mobile-scroll {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .map-tablet {
    display: block;
  }

  .map-mobile,
  .map-mobile-scroll {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 816px) {
  .map-tablet {
    // margin-left: -16px;
  }
}
