.callout {
  background: $steel;
  padding: 30px;
  margin: 0 0 1.25rem;
  overflow: hidden;
  font-family: $font-family-sans-serif;
  color: $jet;

  .h1 {
    font-size: $font-size-h1;
    font-style: normal;
    margin-top: 0;
    color: inherit;

    .big {
      font-size: 1.2em;
      line-height: 1;
    }
  }

  h4 {
    margin-top: 0;
  }

  p {
    font-weight: 300;
    margin-bottom: 1em;
  }

  .button {
    margin: 0;
  }

  .menu {
    list-style: none;
    padding: 0;
    font-family: $font-family-sans-serif;

    li {
      padding-bottom: .25em;
      margin: 0;

      &.active {
        & > a {
          color: $plum-darker;
        }
      }
    }

    a {
      text-decoration: none;
      color: $jet;

      &:hover, &:active, &:focus {
        color: $navy;
      }
    }
  }

  @media (max-width: $screen-md-max) {
    padding: 15px;
  }
}

.aside {
  background: $steel;
  padding: 10px 20px;

  @media (min-width: 545px) {
    width: 50%;
  }

  & > h3:first-of-type {
    margin-top: 0;
  }
}

@media (min-width: $screen-md-min) {
  .archive, .edlibrary {
    .sidebar {
      .btn {
        white-space: normal;
        text-align: left;
        width: 100%;
      }
    }
  }
}
